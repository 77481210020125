<template>
  <div class="activity-detail">
    <div class="top">
      <div class="left-banner">
        <img :src="detailData.cover_image_url" alt="">
      </div>
      <div class="right-desc">
        <div class="title">{{ detailData.title }}</div>

        <div class="free">免费</div>
        <div class="opreation" v-if="type===1">
          <div class="button" @click="signUp">立即报名</div>
          <!--                    <div class="number">已有{{ detailData.enrolls }}人获取</div>-->
        </div>
        <div class="opreation" v-else>
          <div class="button" @click="signUp">已报名联系小助手</div>
          <!--          <div class="number">已有200人报名</div>-->
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="tabs">
        <div class="bar">
          <div class="introduction" @click="chanegTab('introduction')"
               :class="tab === 'introduction'?'active':''">
            资料介绍
          </div>
        </div>
        <div class="con">
          <div class="introduction" v-show="tab === 'introduction'">
            <img :src="detailData.introduction_image_url" alt="">
          </div>
        </div>
      </div>
    </div>
    <div class="qr-code" v-show="isShowQrcode">
      <div class="close-btn iconfont icon-papa-close-small" @click="handleQrcode"></div>
      <div class="title">报名成功</div>
      <img class="qr-image" :src="detailData.qr_code_image_url" alt="">
      <div class="tip">立即添加小助手，发送 <b>{{ detailData.keyword }}</b> 领取</div>
    </div>

  </div>
</template>

<script>
  import * as Api from '@/api/index/index.js'
  import { formatDate } from '@/utils/utils.js'
  import QRCode from 'qrcodejs2'
  import { materialAnalystic, materialRecord } from '@/api/common/common'
  import { mapState } from 'vuex'
  import { ANALYTIC_TYPE } from '@/enums/API'

  export default {
    name: 'ActivityDetail',
    data () {
      return {
        activeName: '',
        detailData: {},
        countdown: '',
        tab: 'introduction',
        isShowQrcode: false,
        type: 1,
        isShowCountDown: true,
        courseId: '',
        timeCount: 1,
        timeInterval: null
      }
    },
    mounted () {
      this.courseId = this.$route.params.id
      this.getDetail()
    },

    computed: {
      ...mapState({
        authorization: state => state.user.authorization
      })
    },
    methods: {
      getDetail () {
        let id = this.courseId
        Api.courseMaterial('', res => {
          this.detailData = res
          this.type = res.is_enroll ? 2 : 1
          if (res.countdown !== 0) {
            this.countdown = formatDate(res.countdown).split(' ')
          } else {
            this.isShowCountDown = false
          }
        }, '', 'v1', id)
      },
      chanegTab (tab) {
        this.tab = tab
      },
      signUp () {
        let params = {
          type: 'click'
        }
        materialAnalystic(this.courseId, params, () => {
        })


        if (this.authorization.access_token) {
          console.log(this.authorization)
          materialRecord(this.courseId, res => {
            console.log(res)
            this.isShowQrcode = true
            this.$store.dispatch('setGlobalMask', {status: true})
          })
          if (this.type === 1) {

          } else {

          }
        } else {
          this.isShowQrcode = true
          this.$store.dispatch('setGlobalMask', {status: true})
        }
      },
      handleQrcode () {
        this.isShowQrcode = !this.isShowQrcode
        this.$store.dispatch('setGlobalMask', {status: false})
      }
    },
    beforeRouteEnter (to, from, next) {
      next(vm => {
        vm.timeCount = 0
        vm.timeInterval = setInterval(() => {
          vm.timeCount++
        }, 1000)
      })

    },
    beforeRouteLeave (to, from, next) {
      clearInterval(this.timeInterval)
      let params = {
        duration: this.timeCount,
        type: ANALYTIC_TYPE.EXIT
      }

      //  小于零表示是从首页点击进入的  需要统计  大于零表示从我的课程进入不需要统计
      if (this.$route.path.indexOf('user') < 0) {
        materialAnalystic(this.courseId, params, () => {
        })
        next()
      }
    },
  }
</script>

<style lang="less">

  .activity-detail {
    width: 100%;
    background: #fff;
    // position: absolute;
    top: 100px;
    left: 0;
    margin-bottom: 30px;
    .top {
      width: 1200px;
      margin: 0 auto;
      height: 446px;
      display: flex;
      align-items: center;

      & > .left-banner {
        width: 704px;
        height: 396px;
        margin-right: 36px;

        & > img {
          width: 100%;
          height: 100%;
        }
      }

      & > .right-desc {
        text-align: left;
        width: calc(1200px - 740px);
        min-height: 396px;
        position: relative;

        & > .countdown {
          font-size: 18px;

          & > .count-num {
            color: #29D087;
          }

          & > .desc {
            margin-bottom: 10px;
            color: #999;
            font-size: 14px;
          }
        }

        & > .title {
          font-size: 26px;
          color: #333;
          font-weight: bold;
        }

        & > .time {
          margin: 45px 0 36px;
        }

        & > .free {
          position: absolute;
          bottom: 60px;
          left: 0;
          color: #FF5864;
          font-size: 26px;
          font-weight: bold;
        }

        & > .opreation {
          position: absolute;
          bottom: 0;
          display: flex;
          align-items: center;

          & > .button {
            width: 180px;
            height: 46px;
            background: rgba(41, 208, 135, 1);
            border-radius: 3px;
            color: #fff;
            font-size: 18px;
            text-align: center;
            line-height: 46px;
            margin-right: 36px;
            cursor: pointer;
          }

          & > .number {
            color: #999;
            font-size: 14px;
          }
        }
      }
    }

    .bottom {
      width: 100%;
      background: #FAFAFA;
      padding-top: 20px;

      & > .tabs {
        width: 1200px;
        margin: 0 auto;
        background: #fff;

        & > .bar {
          width: 100%;
          height: 58px;
          text-align: center;
          line-height: 58px;
          font-size: 16px;
          color: #333;
          font-weight: 500;
          box-sizing: border-box;
          display: flex;
          box-sizing: border-box;
          padding: 0 20px;

          & > div {
            width: 100%;
            cursor: pointer;
          }

          & > .active {
            border-bottom: 4px solid #25D18C;
          }
        }

        & > .con {
          width: 100%;
          box-sizing: border-box;
          padding: 0 20px;

          & > div {
            width: 100%;

            & > img {
              width: 100%;
            }
          }
        }
      }
    }

    .qr-code {
      width: 400px;
      height: 360px;
      background: #fff;
      box-shadow: 0px 13px 46px 0px rgba(86, 86, 86, 0.05);
      border-radius: 5px;
      position: absolute;
      top: 350px;
      left: 50%;
      margin-top: -180px;
      margin-left: -200px;
      z-index: 10000;

      & > .close-btn {
        position: absolute;
        right: 10px;
        top: 10px;
        font-size: 20px;
        cursor: pointer;
      }

      & > .title {
        margin-top: 36px;
        font-size: 24px;
        color: #333;
        font-weight: bold;
      }

      & > .qr-image {
        width: 180px;
        height: 180px;
        border-radius: 5px;
        margin-bottom: 16px;
        margin-top: 10px;
      }

      & > .tip {
        font-size: 18px;
        color: #333;
      }

      & > .reply {
        font-size: 16px;
        color: #666;
        margin-top: 8px;
      }
    }
  }

</style>
